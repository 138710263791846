import { Injectable } from '@angular/core'
import { XlsService } from './xls.service'
import { UserService } from './user.service'
import orderBy from 'lodash-es/orderBy'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SubSink } from 'subsink'
import { ApiService } from './api.service'
import { EmailService } from './email.service'
import { PrimaryCode, TransactionCode } from './enums'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { ChallengeAnswer, ChallengeTransaction, GameBase } from './interfaces'
import { timeOut, use } from './utils'

@Injectable({
  providedIn: 'root'
})
export class ChallengesService {

  private subs = new SubSink()
  private fss: FirestoreService2

  constructor(
    private xlsService: XlsService,
    private g: GlobalService,
    private userService: UserService,
  ) { console.info(`## ${this.constructor.name}`) }

  /**
   * Cria um ficheiro Excel com os resultados dos challenges por participação dos utilizadores
   */
  exportParticipationSegmentByChallenge() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const api = use<ApiService>(ApiService)
    this.subs.add(
      combineLatest([
        this.fss.list('settings', { subcol: 'games', where: [['active', '==', true]] }),
        api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'users' }, true),
        this.fss.list('challenges', { where: [['code', 'in', ['find-the-pair', 'quiz', 'captcha']]] }),
        this.fss.list('segments', { where: [['active', '==', true]] }),
      ]).subscribe((results: any[]) => {
        const games = results[0]
        const users = results[1]
        const challenges = results[2]
        const segments = results[3]
        const res = {}
        const table = []

        for (const ch of challenges.filter(c => c.email)) {
          ch.segment = (users.filter(u => u.email).find(u => u.email.toLowerCase().trim() === ch.email.toLowerCase().trim()) || { segment: null }).segment
        }

        this.subs.unsubscribe()

        for (const game of games) {
          if (!res.hasOwnProperty(game.name)) res[game.name] = {}
          for (const ch of challenges.filter(c => c.challengeId === game.id)) {
            if (!res[game.name].hasOwnProperty(ch.segment)) res[game.name][ch.segment] = 1
            else res[game.name][ch.segment]++
          }
        }

        for (const seg of orderBy(segments, 'name')) {
          const line = { Equipa: seg.name }
          for (const game in res) {
            line[game] = res[game][seg.name] || 0
          }
          table.push(line)
        }

        console.log('TABLE', table)
        this.xlsService.export('games.xlsx', table)
      })
    )
  }

  /**
   * Cria um novo game em settings/NICK/games
   * @param {GameBase} base
   * @param {any} game
   * @returns {Promise<any>}
   */
  createGame(base: GameBase, game: any): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // console.log(base, game);
    const data = { ...game, ...base }
    return this.fss.merge('settings', data.id || this.fss.createId, data, { subcol: 'games' })
  }

  /**
   * Aplica pontos a um utilizador
   * @param {any} user
   * @param {ChallengeTransaction} transaction
   * @returns {Promise<any>}
   */
  applyPointsToUser(user: any, transaction: ChallengeTransaction, timeToFinish = null): Promise<any> {
    console.log('# applyPointsToUser')
    const data = {
      ...transaction,
      active: true,
      code: TransactionCode.ADD_POINTS,
      collection: 'transactions',
      displayName: user.displayName,
      email: user.email.toLowerCase(),
      firstName: user.firstName || '',
      group: user.group || '',
      name: user.name || user.displayName || '',
      orderDate: new Date().toISOString(),
      timeToFinish: timeToFinish,
      primaryCode: PrimaryCode.POINTS,
      segment: user.segment || null,
      type: 'GAME',
      uid: user.uid || user.id || undefined,
      userCode: user.userCode || null,
      xrate: this.g.program.xrate || 1,
    }
    const api = use<ApiService>(ApiService)
    return api.save('Points', 'transactions', [data], 'id')
      .then(() => this.userService.recalcBalance({
        userCode: user.userCode || null,
        email: user.email.toLowerCase(),
        displayName: user.displayName,
        id: user.id,
        nick: this.g.nick
      }))
    // .then(() => this.sendEmailOnFinish(transaction.challengeId, user))
  }

  /**
   * Aplica pontos a um segmento
   * @param {any} user
   * @param {ChallengeTransaction} transaction
   * @returns {Promise<any>}
   */
  applyPointsToSegment(user: any, transaction: ChallengeTransaction, timeToFinish = null): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    console.log('# applyPointsToSegment')
    const data = {
      ...transaction,
      active: true,
      email: user ? (user.email || '').toLowerCase() : '',
      code: TransactionCode.ADD_POINTS,
      group: user.group || '',
      primaryCode: PrimaryCode.POINTS,
      displayName: user.displayName,
      nick: this.g.nick,
      orderDate: new Date().toISOString(),
      timeToFinish: timeToFinish,
      xRate: this.g.program.xrate || 1,
    }
    const api = use<ApiService>(ApiService)
    return this.fss.add('transactions', data)
      .then(() => api.updateSegmentBalance({ nick: this.g.nick, segmentId: data.segment.id }))
    // .then(() => this.sendEmailOnFinish(transaction.challengeId, user))
  }

  private sendEmailOnFinish(challengeId: string, user: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.fss.get('settings', challengeId, { subcol: 'games', api: true }).subscribe(challenge => {
      if (challenge && challenge.emailTemplateOnFinish) {
        this.fss.get('templates', challenge.emailTemplateOnFinish, { api: true }).subscribe(template => {
          if (template) {
            const emailSrv: EmailService = use<EmailService>(EmailService)
            emailSrv.sendOneTemplate(user, template).subscribe()
          }
        })
      }
    })
  }

  /**
   * Grava uma participação num game/challenge segundo os padrões das interfaces
   * @param {any} user
   * @param {ChallengeAnswer} base
   * @param {any} challenge
   * @returns {Promise<any>}
   */
  saveChallengeAnswer(base: ChallengeAnswer, challenge: any, timeToFinish = null, applyPoints = true): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    console.log('# saveChallengeAnswer')
    const data = {
      ...challenge,
      ...base,
      email: base.email && base.email.toLowerCase()
    }

    if(!data.id) data.id = this.fss.createId

    return this.fss.set('challenges', data.id, data).then(() => !data.public && applyPoints && this.applyPointsForChallenge(data, timeToFinish))
  }

  /**
   * Apenas verifica se há postos extras a atribuir
   * Util para exibição dos badges nos ecrãs
   * @param challenge
   * @param answer
   * @returns {any[]}
   */
  verifyExtraPoints(challenge: any, answer: any, preview = false): any[] {
    // console.log('# verifyExtraPoints');
    const points: any[] = []
    if (challenge.scriptPointsConfig) {
      for (const functionType in challenge.scriptPointsConfig) {
        if (!preview) {
          switch (functionType) {
            case 'forParticipating': points.push(challenge.scriptPointsConfig[functionType]); break
            case 'bySegmentParticipation': points.push(challenge.scriptPointsConfig[functionType]); break
            case 'hitAll':
              if (answer.resultQuiz && answer.resultQuiz.filter(r => r === true).length === challenge.questions.length) {
                points.push(challenge.scriptPointsConfig[functionType])
              }
              break
          }
        } else {
          if (challenge.scriptPointsConfig[functionType].points) points.push(challenge.scriptPointsConfig[functionType])
        }

      }
    }
    return preview ? points.filter(p => p.code.toLowerCase().indexOf('segment') === -1) : points
  }

  /**
   * Aplica pontuação extra ao finalizar um challenge
   * @param {Partial<ChallengeAnswer>} answer
   */
  applyPointsForChallenge(answer: Partial<ChallengeAnswer>, timeToFinish, user: any = null) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    console.log('# applyPointsForChallenge')
    if (this.g.program && answer && answer.challengeId) {
      this.subs.add(
        combineLatest([
          this.fss.get('settings', answer.challengeId, { subcol: 'games', api: true }),
          this.fss.get('settings', answer.challengeId, { subcol: 'challenges', api: true }),
          this.fss.get('settings', answer.challengeId, { subcol: 'questionnaires', api: true }),
        ]).pipe(map((results: any[]) => results[0] || results[1] || results[2] || null)).subscribe((challenge: any) => {
          const applyUser = user || this.g.user || null
          console.log(applyUser, challenge, challenge.scriptPointsConfig)
          if (applyUser && challenge && challenge.scriptPointsConfig) {
            for (const functionType in challenge.scriptPointsConfig) {
              switch (functionType) {
                case 'forParticipating': this.forParticipating(challenge, answer, applyUser, this.g.program, timeToFinish); break
                case 'bySegmentParticipation': this.bySegmentParticipation(challenge, answer, applyUser, this.g.program, timeToFinish); break
                case 'hitAll': this.hitAll(challenge, answer, applyUser, this.g.program, timeToFinish); break
              }
            }
          }
        })
      )
      timeOut(() => this.sendEmailOnFinish(answer.challengeId, user || this.g.user), 2500)
    }
  }

  /**
   * Atrubui prontos ao utilizador por participar de um challenge
   * @param challenge
   * @param answer
   * @param user
   * @param program
   */
  private forParticipating(challenge: any, answer: any, user: any, program: any, timeToFinish) {
    console.log('# forParticipating')
    const baseTr: any = {
      email: user.email.toLowerCase(),
      challengeId: answer.challengeId,
      tag: answer.tag || null,
      type: 'GAME',
      segment: user.segment || null,
      description: `[${challenge.name}] ${challenge.scriptPointsConfig.forParticipating.description}`,
      orderPoints: challenge.scriptPointsConfig.forParticipating.points,
      forParticipating: true
    }
    this.applyPointsToUser(user, baseTr, timeToFinish).then().catch()
  }

  /**
   * Atribui pontos a equipa a cada participação de um membro
   * @param challenge
   * @param answer
   * @param user
   * @param program
   */
  private bySegmentParticipation(challenge: any, answer: any, user: any, program: any, timeToFinish) {
    console.log('# bySegmentParticipation')
    if (user.segment) {
      const baseTr: any = {
        segment: user.segment || null,
        tag: answer.tag || null,
        type: 'segments',
        orderPoints: challenge.scriptPointsConfig.bySegmentParticipation.points,
        challengeId: answer.challengeId,
        description: `[${challenge.name}] ${challenge.scriptPointsConfig.bySegmentParticipation.description}`,
        bySegmentParticipation: true
      }
      this.applyPointsToSegment(user, baseTr, timeToFinish).then().catch()
    }
  }

  /**
   * Atrubui pontos por ter acertado todas as respostas de um quiz (100%)
   * @param challenge
   * @param answer
   * @param user
   * @param program
   */
  private hitAll(challenge: any, answer: any, user: any, program: any, timeToFinish) {
    console.log('# hitAll')
    const perguntas = challenge.questions ? challenge.questions.length : answer.result ? challenge.fields.filter(f => f.name !== 'html').length : 0
    // console.log('#perguntas', perguntas)
    if (answer.resultQuiz && answer.resultQuiz.filter(r => r === true).length === perguntas) {
      const baseTr: any = {
        email: user.email.toLowerCase(),
        orderPoints: challenge.scriptPointsConfig.hitAll.points,
        segment: user.segment || null,
        description: `[${challenge.name}] ${challenge.scriptPointsConfig.hitAll.description}`,
        tag: answer.tag || null,
        type: 'GAME',
        challengeId: answer.challengeId,
        hitAll: true
      }
      this.applyPointsToUser(user, baseTr, timeToFinish).then().catch()
    }
    if (answer.result) {
      if (answer.result.length >= challenge.fields.filter(f => f.name !== 'html').length) {
        const baseTr: any = {
          email: user.email.toLowerCase(),
          orderPoints: challenge.scriptPointsConfig.hitAll.points,
          segment: user.segment || null,
          description: `[${challenge.name}] ${challenge.scriptPointsConfig.hitAll.description}`,
          type: 'FORM',
          challengeId: answer.challengeId,
          formId: answer.challengeId,
          hitAll: true
        }
        this.applyPointsToUser(user, baseTr, timeToFinish).then().catch()
      }
    }
  }

  /**
   * Atrubui prontos ao utilizador por votar num challenge
   * @param challenge
   * @param answer
   * @param user
   * @param program
   */
  forVoting(challenge: any, answer: any, user: any, program: any) {
    console.log('# forParticipating')
    const baseTr: any = {
      email: user.email.toLowerCase(),
      challengeId: answer.challengeId,
      tag: answer.tag || null,
      type: 'GAME',
      segment: user.segment || null,
      description: `[${challenge.name}] ${challenge.votingParams.points} pontos por votar`,
      orderPoints: challenge.votingParams.points,
      forVoting: true
    }
    this.applyPointsToUser(user, baseTr).then().catch()
  }

  clearAllParticipations(id: string) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const api = use<ApiService>(ApiService)
    combineLatest([
      this.fss.list('challenges', { api: true, where: [['challengeId', '==', id]] }),
      api.post('db/action', { action: 'list', nick: this.g.nick, collection: 'transactions', api: true, queryArray: [['challengeId', '==', id]] }, true),
    ]).subscribe((results: any[]) => {
      if (results[0]) this.fss.deleteBatch('challenges', results[0]).then()
      if (results[1]) this.fss.deleteBatch('transactions', results[1]).then()
    })
  }

  getGalleryFiles(config): any {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Promise(resolve => {
      this.subs.add(
        this.fss.list(`settings/${this.g.program.nick}/challenges/${config.id}/files`, { rawPath: true, api: true }).subscribe(files => {
          if (files && files.length) {
            const rawFiles = files
            const votes = rawFiles.filter(o => o.votes && o.votes.includes(this.g.user.email.toLowerCase())).length
            // console.log({ votes: votes, files: rawFiles })
            resolve({ votes: votes, files: rawFiles })
          }
        })
      )
    })
  }

  getGalleryFilesFromChallenge(config, challengeId): any {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Promise(resolve => {
      this.subs.add(
        this.fss.list('challenges', { where: [['active', '==', true], ['challengeId', '==', challengeId], ['type', '==', 'CHALLENGE']], api: true }).subscribe(challenges => {
          if (challenges && challenges.length) {
            // console.log(challenges)
            const rawFiles = []

            for (const challenge of challenges) {
              if (challenge.answer) {
                rawFiles.push({ ...challenge.answer, email: challenge.email, id: challenge.id, folder: !challenge.answer.type || challenge.answer.type === 'image-upload' ? 'Fotos' : 'Frases', votes: challenge.votedBy })
              }
            }

            const votes = challenges.filter(o => o.votedBy && o.votedBy.includes(this.g.user.email.toLowerCase())).length
            // console.log({ votes: votes, files: rawFiles })
            resolve({ votes: votes, files: rawFiles })
          } else { resolve({ votes: 0, files: [] }) }
        })
      )
    })
  }

  getGalleryFilesFromChallengeObs(config, challengeId): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.list('challenges', { where: [['active', '==', true], ['challengeId', '==', challengeId], ['type', '==', 'CHALLENGE']] }).pipe(map(challenges => {
      if (challenges && challenges.length) {
        const rawFiles = []

        for (const challenge of challenges) {
          if (challenge.answer) {
            rawFiles.push({ ...challenge.answer, email: challenge.email, id: challenge.id, folder: !challenge.answer.type || challenge.answer.type === 'image-upload' ? 'Fotos' : 'Frases', votes: challenge.votedBy })
          }
        }

        const votes = challenges.filter(o => o.votedBy && o.votedBy.includes(this.g.user.email.toLowerCase())).length
        return { votes: votes, files: rawFiles }
      } else {
        return { votes: 0, files: [] }
      }
    }))
  }

}
